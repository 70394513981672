import React, { Component } from "react";
import { validateForm } from "../utils/validateForm";
import { Modal, Spinner, Form, Col, Alert, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { setInStorage, getFromStorage } from "../utils/storage";
import {
  userLogin,
  setUser,
  setIsFoundation,
  setIsEnterprise,
  setUserId,
  setToken,
  setShowPaymentModal,
  setShowingPaymentModal
} from "../Redux/modules/auth";
import { setShowOrgPromo } from "../Redux/modules/campaign";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import AppSpinner from "../Extras/AppSpinner";
import GoogleLogo from "./styles/img/Google_Logo.png";
import { wsConnect } from "../Redux/modules/websocket";
import wsUrl from "../utils/wsUrl";
import queryString from "query-string";
import { withRouter } from "react-router";
import PhoneInput from "react-phone-input-2";
import "./styles/RegisterModal.scss";
import "./styles/login-styles.scss";

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(userLogin()),
    setUser: user => dispatch(setUser(user)),
    setUserId: userId => dispatch(setUserId(userId)),
    setIsFoundation: isFoundation => dispatch(setIsFoundation(isFoundation)),
    setIsEnterprise: isEnterprise => dispatch(setIsEnterprise(isEnterprise)),
    setToken: token => dispatch(setToken(token)),
    setShowOrgPromo: show => dispatch(setShowOrgPromo(show)),
    wsConnect: host => dispatch(wsConnect(host)),
    setShowPaymentModal: show => dispatch(setShowPaymentModal(show)),
    setShowingPaymentModal: show => dispatch(setShowingPaymentModal(show))
  };
}

const select = state => {
  return {
    language: state.auth.language,
    user: state.auth.user,
  };
};

class ConnectedLoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginEmail: "",
      loginPassword: "",
      isLoading: false,
      incorrectData: false,
      isLoadingSignin: false,
      signinError: "",
      loginMethod: "",
      cellPhoneVerified: false,
      smsVerificationCode: "",
      sendingSmsCode: false,
      showSentCodeAlert: false,
      smsVerificationError: "",
      cellPhone: "",
      cellPhoneVerificationCodeSent: false,
      phoneVerificationMethod: "sms",

    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSignInSubmit = this.handleSignInSubmit.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.updateUserSession = this.updateUserSession.bind(this);
    this.responseGoogleSuccess = this.responseGoogleSuccess.bind(this);
    this.responseGoogleFailure = this.responseGoogleFailure.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.openRegisterModal = this.openRegisterModal.bind(this);
    this.handleSmsCodeChange = this.handleSmsCodeChange.bind(this);
    this.handleVerifyCellPhone = this.handleVerifyCellPhone.bind(this);
  }

  onInputChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleInputChange(e) {

    this.setState({ [e.target.name]: e.target.value });

  }

  async handleSmsCodeChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value.length === 4) {
      this.setState({ sendingSmsCode: true });
      const prom = await fetch("/sms/verifyCode", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          cellPhone: "+" + this.state.cellPhone,
          code: event.target.value,
          userId: this.props.user._id
        })
      });
      const res = await prom.json();
      if (res.success) {
        this.setState({
          cellPhoneVerified: true,
          showSentCodeAlert: false,
          smsVerificationError: "",
          sendingSmsCode: false
        });
        if (res.user) {
          this.setState({ isLoading: false });
          setInStorage("nibi_app", { token: res.token });
          await this.updateUserSession(
            res.user._id,
            false,
            false,
            res.token
          );
        } else {
          this.setState({
            smsVerificationError:
              "Error al iniciar sesión. Si eres fundación o empresa por favor inicia sesión usando tu correo electrónico y contraseña.",
            showSentCodeAlert: false,
            smsVerificationCode: "",
            sendingSmsCode: false
          });
        }
      } else {
        this.setState({
          smsVerificationError:
            "Ha ocurrido un error al verificar tu número de celular. Inténtalo nuevamente o asegúrate de ingresar el código correctamente.",
          showSentCodeAlert: false,
          smsVerificationCode: "",
          sendingSmsCode: false
        });
      }
    }
  }

  async handleVerifyCellPhone() {
    this.setState({
      cellPhoneVerificationCodeSent: true,
      showSentCodeAlert: true,
      smsVerificationError: ""
    });
    const prom = await fetch("/sms/verificationLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        cellPhone: "+" + this.state.cellPhone,
        phoneVerificationMethod: this.state.phoneVerificationMethod
      })
    });

    const resp = await prom.json();
  }

  async handleSignIn() {
    const email = this.state.loginEmail;
    const password = this.state.loginPassword;

    // No borrar!
    const userWithGoogleFetch = fetch("/signin/google_facebook/get", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        withGoogle: true
      })
    });

    const userWithFbFetch = fetch("/signin/google_facebook/get", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        withFB: true
      })
    });

    const userWithGoogleProm = await userWithGoogleFetch;
    const userWithFbProm = await userWithFbFetch;

    const userWithGoogle = await userWithGoogleProm.json();
    const userWithFb = await userWithFbProm.json();

    if (userWithGoogle.success && userWithGoogle.userFound) {
      this.setState({
        signinError:
          'Parece que estás intentando iniciar sesión con una cuenta de Google. Da click en "Continúa con Google" para ingresar.'
      });
      return true;
    } else if (userWithFb.success && userWithFb.userFound) {
      this.setState({
        signinError:
          'Parece que estás intentando iniciar sesión con una cuenta de Facebook. Da click en "Continúa con Facebook" para ingresar.'
      });
      return true;
    }

    const fetchParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    };

    const fetchUsers = fetch("/signin/account/signin", fetchParams);
    const fetchFoundations = fetch("/foundations/signin", fetchParams);
    const fetchEnterprises = fetch("/enterprises/signin", fetchParams);

    const promUsers = await fetchUsers;
    const jsonUsers = await promUsers.json();

    const promFoundations = await fetchFoundations;
    const jsonFoundations = await promFoundations.json();

    const promEnterprises = await fetchEnterprises;
    const jsonEnterprises = await promEnterprises.json();

    let respJson;

    if (jsonUsers.success) respJson = jsonUsers;
    else if (jsonFoundations.success) respJson = jsonFoundations;
    else if (jsonEnterprises.success) respJson = jsonEnterprises;

    if (respJson) {
      setInStorage("nibi_app", { token: respJson.token });
      await this.updateUserSession(
        respJson.userId,
        respJson.isFoundation,
        respJson.isEnterprise,
        respJson.token
      );

      return respJson.success;
    }

    return false;
  }

  async updateUserSession(userId, isFoundation, isEnterprise, token) {
    this.props.setIsFoundation({ isFoundation });
    this.props.setIsEnterprise({ isEnterprise });
    this.props.setToken({ token });

    if (isEnterprise) {
      const dataEnterprise = await fetch("/enterprises/" + userId);
      const res = await dataEnterprise.json();

      this.props.setUser({ user: res });
    } else if (isFoundation) {
      const dataFoundation = await fetch("/foundations/foundation/" + userId);
      const res = await dataFoundation.json();

      const dataFoundationInfo = await fetch(
        "/foundations/foundation/info/" + res.foundationId
      );
      const res2 = await dataFoundationInfo.json();

      this.props.setUser({ user: res2 });

      const objPromo = getFromStorage("orgPromo");
      if (!objPromo || !objPromo.show) {
        this.props.setShowOrgPromo(true);
        setInStorage("orgPromo", { show: true });
      }
      this.props.history.push("/dashboardFoundation/dashboard");
    } else {
      const dataUser = await fetch("/users/user/" + userId);
      const res = await dataUser.json();
      this.props.setUser({ user: res });
      if (this.props.showingPaymentModal) {
        this.props.setShowPaymentModal(true);
        this.props.setShowingPaymentModal(false);
      }
    }

    this.props.setUserId({ userId });
    this.props.userLogin();
    this.props.wsConnect(`${wsUrl}/${token}`);
    this.setState({ loginPassword: "", loginEmail: "", loginMethod: "", cellPhone: "", cellPhoneVerified: false, smsVerificationCode: "", cellPhoneVerificationCodeSent: false });
  }

  async handleSignInSubmit(e) {
    e.preventDefault();
    const valid = validateForm(e);

    if (valid) {
      this.setState({ isLoading: true, incorrectData: false });
      var validUser = await this.handleSignIn();

      this.setState({ isLoading: false });

      if (!validUser) this.setState({ incorrectData: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      if (this.props.show) {
        this.props.handleShowLoginModal();
      } else if (!this.props.show) {
        this.setState({ signinError: "" });
        this.props.handleCloseLoginModal();
      }
    }

    if (this.props.loggedIn !== prevProps.loggedIn) {
      this.setState({ signinError: "" });
      this.props.handleCloseLoginModal();
    }
  }

  async responseGoogleSuccess(response) {
    this.setState({ isLoadingSignin: true });

    const tokenId = response.tokenId;

    const promLogin = await fetch("/signin/google/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: tokenId
      })
    });

    const jsonLogin = await promLogin.json();

    if (jsonLogin.success) {
      setInStorage("nibi_app", { token: jsonLogin.token });
      await this.updateUserSession(
        jsonLogin.userId,
        jsonLogin.isFoundation,
        jsonLogin.isEnterprise,
        jsonLogin.token
      );
    } else {
      this.setState({ signinError: jsonLogin.message });
    }

    this.setState({ isLoadingSignin: false });
  }

  responseGoogleFailure() {
    //
  }

  async responseFacebook(response) {
    this.setState({ isLoadingSignin: true });

    const { email, accessToken, userID, name } = response;

    const promLogin = await fetch("/signin/facebook/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        accessToken,
        userID,
        name
      })
    });

    const jsonLogin = await promLogin.json();

    if (jsonLogin.success) {
      setInStorage("nibi_app", { token: jsonLogin.token });
      await this.updateUserSession(
        jsonLogin.userId,
        jsonLogin.isFoundation,
        jsonLogin.isEnterprise,
        jsonLogin.token
      );
    } else {
      this.setState({ signinError: jsonLogin.message });
    }

    this.setState({ isLoadingSignin: false });
  }

  openRegisterModal() {
    this.props.handleCloseLoginModal();
    this.props.handleShowRegisterModal();
  }

  render() {
    const {
      isLoading,
      isLoadingSignin,
      incorrectData,
      signinError,
      loginMethod,
      cellPhoneVerified,
      smsVerificationCode,
      sendingSmsCode,
      showSentCodeAlert,
      smsVerificationError,
      cellPhone,
      cellPhoneVerificationCodeSent,
      phoneVerificationMethod
    } = this.state;
    const { language } = this.props;

    const LOGIN = language === "ES" ? "Ingresa a tu cuenta" : "Log in";
    const LOGIN_2 = language === "ES" ? "Ingresar" : "Log in";
    const FORGOT_PASSWORD =
      language === "ES" ? "¿Olvidaste tu contraseña?" : "Mot de passe oublié?";
    const NO_ACCOUNT_1 =
      language === "ES"
        ? "¿Aún no tienes cuenta? "
        : "Vous n'avez pas de compte? ";
    const NO_ACCOUNT_2 =
      language === "ES" ? "Regístrate aquí" : "Créez-en un ici";
    const WITH_GOOGLE =
      language === "ES" ? "Entrar con Google" : "Continuez avec Google";
    const WITH_FACEBOOK =
      language === "ES" ? "Entrar con Facebook" : "Continuez avec Facebook";
    const INCORRECT_DATA =
      language === "ES" ? "Datos incorrectos" : "Données incorrectes";
    const INSERT_EMAIL = language === "ES" ? "Correo electrónico" : "Email";
    const INSERT_PASSWORD = language === "ES" ? "Contraseña" : "Mot de passe";

    const values = queryString.parse(this.props.location.search);
    const loginRequired = values.sl === "true";

    if (isLoadingSignin) {
      return (
        <div>
          <Modal
            show={this.props.show}
            onHide={!loginRequired ? this.props.handleCloseLoginModal : null}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {LOGIN}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AppSpinner />
            </Modal.Body>
          </Modal>
        </div>
      );
    }

    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={!loginRequired ? this.props.handleCloseLoginModal : null}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {LOGIN}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ paddingBottom: "50px" }}>
            <form
              className="needs-validation"
              noValidate={true}
              onSubmit={this.handleSignInSubmit}
            >
              {!loginMethod &&
                <div>
                  <h5>Ingresa mediante: </h5>
                  <div
                    className="nibi-btn"
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ loginMethod: "email" });
                    }}
                  >
                    Correo electrónico y contraseña
                  </div>
                  <div
                    className="nibi-btn"
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ loginMethod: "cellPhone" });
                    }}
                  >
                    Número telefónico
                  </div>
                </div>}
              {loginMethod &&
                <h6
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    this.setState({ loginMethod: "" });
                  }}
                >
                  Cambiar método de inicio de sesión
                </h6>}
              {loginMethod === "email" &&
                <div>
                  <div className="form-group">
                    <input
                      name="email"
                      type="text"
                      className="form-control"
                      id="loginEmail"
                      placeholder={INSERT_EMAIL}
                      value={this.state.loginEmail}
                      onChange={this.onInputChange}
                      maxLength={254}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      name="password"
                      type="password"
                      className="form-control"
                      id="loginPassword"
                      placeholder={INSERT_PASSWORD}
                      value={this.state.loginPassword}
                      onChange={this.onInputChange}
                      maxLength={254}
                      required
                    />
                  </div>
                </div>}

              {loginMethod === "cellPhone" && (<div><Form.Row><Form.Group as={Col}>
                <Form.Label className="nibi-label">
                  Número de teléfono (no olvides incluir el indicativo de tu
                  país)
                </Form.Label>
                <InputGroup>
                  <PhoneInput
                    country="co"
                    name="cellPhone"
                    enableSearch={true}
                    value={cellPhone}
                    onChange={(e) => {
                      this.setState({ cellPhone: e });
                      if (e !== this.state.originalPhone) {
                        this.setState({ cellPhoneVerified: false });
                      } else if (this.state.cellPhoneVerifiedOriginal) {
                        this.setState({ cellPhoneVerified: true });
                      }
                    }}
                    isValid={cellPhoneVerified}
                    isInvalid={!cellPhoneVerified}
                    required
                  />
                </InputGroup>
              </Form.Group>
                {!cellPhoneVerified && !cellPhoneVerificationCodeSent && (
                  <Form.Group>
                    <Col sm={12}>
                      <button
                        onClick={this.handleVerifyCellPhone}
                        className="nibi-btn"
                      >
                        Enviar código de verificación
                      </button>
                    </Col>
                  </Form.Group>
                )}
                {cellPhoneVerificationCodeSent &&
                  !sendingSmsCode &&
                  !cellPhoneVerified && (
                    <>
                      <Form.Group as={Col} sm={6}>
                        <Form.Label className="profileLabel">
                          Código de verificación:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="smsVerificationCode"
                          value={smsVerificationCode}
                          onChange={this.handleSmsCodeChange}
                          placeholder="- - - -"
                          maxLength={4}
                          required
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            textDecoration: "underline",
                            cursor: "pointer"
                          }}
                          onClick={this.handleVerifyCellPhone}
                        >
                          Reenviar código
                        </p>
                      </Form.Group>
                    </>
                  )}
              </Form.Row>
                {sendingSmsCode && (
                  <Spinner size="md" animation="border" variant="warning" />
                )}
                {showSentCodeAlert && (
                  <Alert
                    variant="success"
                    onClose={() => this.setState({ showSentCodeAlert: false })}
                    dismissible
                  >
                    <Alert.Heading>Código enviado</Alert.Heading>
                    <p>
                      Hemos enviado un código de verificación de 4 dígitos a
                      tu número de celular. Por favor ingrésalo en el campo
                      correspondiente para validar tu número de celular.
                    </p>
                  </Alert>
                )}
                {smsVerificationError && (
                  <Alert
                    variant="danger"
                    onClose={() => this.setState({ smsVerificationError: "" })}
                    dismissible
                  >
                    <Alert.Heading>Error de verificación</Alert.Heading>
                    <p>{smsVerificationError}</p>
                  </Alert>
                )}</div>)}





              {signinError &&
                <div className="signup-error">
                  {signinError}
                </div>}

              {incorrectData &&
                <div className="incorrectData">
                  {INCORRECT_DATA}
                </div>}

              {loginMethod === "email" && (<div className="login-btn-section">
                <span>
                  <a className="ml-auto forgot-password" href="/password_reset">
                    {FORGOT_PASSWORD}
                  </a>
                </span>
                <button
                  id="btnLogin"
                  type="submit"
                  className="hexagon-btn modal-button"
                  disabled={isLoading}
                >
                  {isLoading
                    ? <Spinner size="sm" animation="border" variant="dark" />
                    : LOGIN_2}
                </button>
              </div>)}
              <hr />

              <Form.Row className="google-fb-signup">
                <Col md={6}>
                  <GoogleLogin
                    clientId="360716219899-r9i7vsuekq108povt1m5cprsoflqndto.apps.googleusercontent.com"
                    buttonText={WITH_GOOGLE}
                    onSuccess={this.responseGoogleSuccess}
                    onFailure={this.responseGoogleFailure}
                    render={renderProps =>
                      <button
                        className="hexagon-btn light-color google-login-btn"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <div className="logo">
                          <img
                            src={GoogleLogo}
                            alt="Entrar con Google"
                            style={{ height: 23, width: 23 }}
                          />
                        </div>
                        <span className="text">
                          {WITH_GOOGLE}
                        </span>
                      </button>}
                  />
                </Col>
                <Col md={6}>
                  <FacebookLogin
                    appId="207412983912631"
                    fields="name,email"
                    callback={this.responseFacebook}
                    cssClass="fb-login-btn light-color hexagon-btn"
                    icon={<i className="fab fa-facebook" />}
                    textButton={WITH_FACEBOOK}
                    isMobile={false}
                  />
                </Col>
              </Form.Row>
              <div className="center register-login-link">
                {NO_ACCOUNT_1}{" "}
                <span
                  onClick={this.openRegisterModal}
                  className="redirectLoginLink"
                >
                  {NO_ACCOUNT_2}
                </span>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const LoginModal = connect(select, mapDispatchToProps)(ConnectedLoginModal);

export default withRouter(LoginModal);
